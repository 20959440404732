import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box, Skeleton, Stack } from '@mui/material';
import debounce from 'just-debounce-it';
import { v4 as uuidv4 } from 'uuid';
import { joiResolver } from '@hookform/resolvers/joi';
import { useQueryClient, useMutation, useIsMutating } from '@tanstack/react-query';

import { useAppContext } from '../../Store';
import { updateDocEditMetaData, updateDocEditMetaDataVariableMode } from '../../services/';
import { UPDATE_FORM_STATE_AND_SOURCE, UPDATE_VIEW_DOC_USER } from '../../reducer';
import { UserRoles } from '../../constants';
import DocEditContent from '../../components/DocEditContent';
import { useForm, UseFormReturn } from 'react-hook-form';
import {
  deleteForm,
  fetchAttachments,
  fetchDocEditDocument,
  fetchRanges,
  fetchRules,
  publishForm,
  saveAttachments,
  saveRanges,
  saveRules,
} from '../../api';
import { getValidationSchemaByRole, notify } from '../../utils/helper';
import { useDocEditFileQuery } from '../../services/getDocEditFile';
import { useGetOrg } from '../../services/getOrg';
import NotFound from '../NotFound';

const AUTOSAVE_DEBOUNCE = 3500;

type DocEditParams = {
  id: string;
};

type SimpleRule = {
  unit: string;
  subunit: string;
  product: string;
  lineOfBusiness: string;
  businessType: string;
};

type ProductMetadata = {
  id?: string;
  isActive: boolean;
};

// index, simpleRule
export type RawRule = [number, SimpleRule, ProductMetadata];

interface DocEditProps {
  onChange: () => void;
  control: UseFormReturn['control'];
  formState: UseFormReturn['formState'];
  handleSubmit: UseFormReturn['handleSubmit'];
  setValue: UseFormReturn['setValue'];
  trigger: UseFormReturn['trigger'];
  watch: UseFormReturn['watch'];
  requiredKeys: string[];
}

function DocEdit({
  onChange,
  control,
  formState,
  handleSubmit,
  trigger,
  watch,
  requiredKeys,
}: DocEditProps) {
  const { id } = useParams<DocEditParams>();
  const { data: docEditFileData, isLoading: isPageLoading } = useDocEditFileQuery(id);
  const { isLoading: isOrgDataLoading, data: orgData } = useGetOrg();
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);
  const [isPublishLoading, setIsPublishLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState<[string, 'success' | 'error'] | null>(null);
  const navigate = useNavigate();
  const { dispatch } = useAppContext();
  const intervalRef = useRef<any>();
  const location = useLocation();

  const { errors, isValid } = formState;

  const handleBeforeUnload = (e: any) => {
    e.preventDefault();
    //old browser support
    e.returnValue = 'nonemptystring';
  };

  useEffect(() => {
    if (isLoading) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    } else {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isLoading]);

  if (isPageLoading || isLoading || isOrgDataLoading) {
    return (
      <div
        className="click-overlay"
        style={
          isLoading
            ? {
                position: 'fixed',
                top: '0px',
                paddingTop: '50px',
                width: '100%',
                height: '100%',
                zIndex: 9999,
              }
            : {}
        }
      >
        <Box className="wrapper container">
          <Box className="content-box">
            <Stack spacing={2} sx={{}}>
              <Stack spacing={1} direction="row" sx={{ pb: 4 }}>
                <Skeleton variant="text" sx={{ fontSize: '2rem' }} width={70} />
                <Skeleton variant="text" sx={{ fontSize: '2rem' }} width={80} />
              </Stack>
              <Stack spacing={2} sx={{ pb: 2 }}>
                <Skeleton variant="rounded" width="50%" height={60} />
                <Skeleton variant="rounded" width="50%" height={60} />
              </Stack>
              <Skeleton variant="rounded" height={40} />
              <Stack spacing={1} direction="row">
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={100} height={60} />
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={100} height={60} />
              </Stack>
            </Stack>
          </Box>
        </Box>
      </div>
    );
  }

  const handleCreate = async () => {
    trigger();
  };

  //TODO: typing rules causes a ts error
  const handleBulkSave = async (metadata: any, rules: any) => {
    if (!id) return;

    notify(
      'Please do not navigate away or refresh the page. Processing may take a few moments.',
      'warn',
      6000
    );

    setIsLoading(true);

    try {
      //fetch ranges for each rule
      let rangesPromiseArray = await Promise.all(
        rules.map(async (rawRule: RawRule, index: number) => {
          const ranges = await fetchRanges(rawRule[2].id ?? id, index);
          return ranges.data.ranges;
        })
      );

      let attachments = await fetchAttachments(id);
      //create form and get form ID
      const formFields: any = await updateDocEditMetaData(id, metadata);
      queryClient.invalidateQueries({ queryKey: ['docEditFile'] });
      dispatch({ type: UPDATE_VIEW_DOC_USER, payload: formFields.data.user.email });

      if (formFields && 'data' in formFields && formFields.data?.id) {
        const formId = formFields.data.id;
        try {
          // Use form ID to save each rule (rules have an associated elastic ID)
          const savedRules: RawRule[] = await saveRules(rules, formId);
          // PM user navigates to elastic ID Edit Form page with link data, save form#link
          if (location.state?.link) {
            await updateDocEditMetaData(formId, {}, { link: location.state.link });
          }
          // Save ranges for each saved rule
          await Promise.allSettled(
            savedRules.map(async (_: RawRule, index: number) => {
              try {
                await saveRanges(formId, index, rangesPromiseArray[index]);
              } catch (error) {
                console.error(`Failed to save ranges for rule at index ${index}:`, error);
                notify(`Failed to save ranges for rule at index ${index}`, 'error');
              }
            })
          );
        } catch (error) {
          console.error('Failed to save rules or update metadata:', error);
          notify('Failed to save rules or update metadata', 'error');
        }
        // save attachments
        if (attachments && attachments.data) {
          const requestId = uuidv4();
          await Promise.all(
            attachments.data.map(async (attachment: any) => {
              const fileType = attachment.fileName.slice(
                1 + attachment.fileName.lastIndexOf('.'),
                attachment.fileName.length
              );
              attachment.requestId = requestId;
              attachment.contentType = fileType;
              return await saveAttachments(formId, attachment);
            })
          );
        }
        dispatch({
          type: UPDATE_FORM_STATE_AND_SOURCE,
          payload: { status: 'EDIT', formSource: 'FORM' },
        });
        if (id !== formId) navigate(`/forms/${formId}`);
        setAlertMessage([formFields?.message, 'success']);
        setOpen(true);
      }
    } catch (error) {
      console.error('An error occurred during the bulk save process:', error);
      notify('An error occurred during the bulk save process', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handlePublish = async () => {
    setIsPublishLoading(true);

    const { data } = docEditFileData;
    if (data) {
      const { canModifyFormNumber, metadata } = data;
      const options: any = {
        canModifyFormNumber,
      };
      try {
        await updateDocEditMetaData(id!, metadata, options);
      } catch (error) {
        setIsPublishLoading(false);
        return;
      }
    }

    const attachmentData = await fetchAttachments(id!);
    const rulesData = await fetchRules(id!);

    let isValidForPublish = !!attachmentData?.data.some((x: any) => x.category && x.category === 'primary')
      && !!rulesData?.data?.length;

    if (!isValidForPublish) {
      setOpen(true);
      setAlertMessage([
        'At least one product and selected attachment are required to publish this form.',
        'error',
      ]);
      setIsPublishLoading(false);
      return;
    }

    let archivedProducts = rulesData.data.some((rule: any) => {
      if (!rule[2].isActive) return false;

      let org =
        rule[1].unit + '|' + rule[1].subunit + '|' + rule[1].product + '|' + rule[1].lineOfBusiness;
      let found = orgData?.hierarchyOptionsWithLOB.find(
        (option) => option.value === org && option?.archived
      );

      if (found) return true;
      else return false;
    });

    if (archivedProducts) {
      setIsPublishLoading(false);
      setOpen(true);
      setAlertMessage(['Archived Org Hierarchy preventing publish', 'error']);
      return;
    }

    await publishForm(id!);

    let count = 0;
    intervalRef.current = setInterval(async () => {
      const data = await fetchDocEditDocument(id!);
      count = count + 1;
      if (data?.data?.status === 'PUBLISHED') {
        queryClient.invalidateQueries({ queryKey: ['docEditFile'] });
        setOpen(true);
        clearInterval(intervalRef.current);
        setAlertMessage(['Form has been published.', 'success']);
        setIsPublishLoading(false);
        dispatch({
          type: UPDATE_FORM_STATE_AND_SOURCE,
          payload: { status: 'PUBLISHED', source: 'FORM' },
        });
      } else if (count === 15) {
        setOpen(true);
        setIsPublishLoading(false);
        clearInterval(intervalRef.current);
        setAlertMessage(['Form failed to publish.', 'error']);
      }
    }, 2000);
  };

  const handleDelete = async (loaderFunc?: () => void) => {
    await deleteForm(id!);

    let count = 0;
    intervalRef.current = setInterval(async () => {
      const data = await fetchDocEditDocument(id!);
      count = count + 1;
      if (data?.message === '404') {
        clearInterval(intervalRef.current);
        notify(`Form ${id} was deleted`, 'success');
        loaderFunc && loaderFunc();
        navigate('/forms');
      } else if (count === 15) {
        clearInterval(intervalRef.current);
        notify(`Form ${id} failed to be deleted`, 'error');
        loaderFunc && loaderFunc();
      }
    }, 2000);
  };

  return (
    <DocEditContent
      control={control}
      errors={errors}
      handleBulkSave={handleBulkSave}
      handlePublish={handlePublish}
      handleDelete={handleDelete}
      handleSave={handleSubmit(handleCreate)}
      id={id}
      isLoading={isLoading}
      open={open}
      setOpen={setOpen}
      alertMessage={alertMessage}
      title="Edit Form"
      viewDoc={docEditFileData?.data}
      isPublishLoading={isPublishLoading}
      watch={watch}
      isValid={isValid}
      onChange={onChange}
      requiredKeys={requiredKeys}
    />
  );
}

interface AutosaveProps {
  defaultValues: any;
}

function AutoSaveComponent({ defaultValues }: AutosaveProps) {
  const { state } = useAppContext();
  const { auth } = state;
  const validationSchema = getValidationSchemaByRole(auth.roles as UserRoles[]);
  const { id } = useParams<DocEditParams>();
  const { data: docEditFileData, error: docEditFileError } = useDocEditFileQuery(id);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [canModifyFormNumber, setCanModifyFormNumber] = useState(false);
  const formKeys = validationSchema?.$_terms?.keys || []
  const requiredKeys : string[]= formKeys
    .filter((k: any) => {
      const flags = k.schema?._flags;
      return flags && Object.keys(flags).length > 0 && flags.presence === 'required';
    })
    .map((k: any) => k.key) || []; 
    const mutationResult = useMutation({
      mutationKey: ['updateDocEditFile'],
      mutationFn: updateDocEditMetaDataVariableMode,
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: ['docEditFile'] });
      },
      onError: (error: any) => {
        if (error.scope) {
          error.scope.forEach((fieldName: string) => {
            setValue(fieldName, '');
          });
          trigger();
        }
      },
    });
  const { control, reset, watch, getValues, formState, handleSubmit, setValue, trigger } =
    useForm<any>({
      mode: 'all',
      criteriaMode: 'all',
      resolver: joiResolver(validationSchema),
    });
  const { mutateAsync } = mutationResult;

  let options = useMemo(() => {
    return {
      canModifyFormNumber: canModifyFormNumber,
    };
  }, [canModifyFormNumber]);

  const isMutatingDocEditFile = useIsMutating({ mutationKey: ['updateDocEditFile'] });

  const handleDebouncedChange = React.useMemo(
    () =>
      debounce(
        (data: UpdateDocEditMetaDataVariableModeI) => !isMutatingDocEditFile && mutateAsync(data),
        AUTOSAVE_DEBOUNCE
      ),
    [mutateAsync, isMutatingDocEditFile]
  );

  useEffect(() => {
    const data = docEditFileData?.data;
    if (data) {
      if (data?.id && id !== data?.id) {
        navigate(`/forms/${data.id}`, { replace: true });
      }

      if (data?.canModifyFormNumber) {
        setCanModifyFormNumber(data.canModifyFormNumber);
      }
    }
  }, [docEditFileData, id, navigate]);

  useEffect(() => {
    if (docEditFileData?.data?.metadata) {
      reset(docEditFileData.data.metadata);
    }
  }, [docEditFileData?.data?.metadata, reset]);

  const onChange = async () => {
    const data = getValues();
    try {
      const { value } = validationSchema.validate(data);
      if (id) {
        handleDebouncedChange({
          id,
          metadata: value,
          options,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return !docEditFileError ? (
    <DocEdit
      onChange={onChange}
      control={control}
      formState={formState}
      handleSubmit={handleSubmit}
      setValue={setValue}
      trigger={trigger}
      watch={watch}
      requiredKeys={requiredKeys}
    />
  ) : (
    <NotFound />
  );
}

export default AutoSaveComponent;
