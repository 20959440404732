import React, { FormEventHandler, useCallback, useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import MuiTextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Button from '../styledComponents/Button';
import { FileUpload } from './partials/FileUpload';
import TextField from '../styledComponents/TextField';
import SelectedProducts from './partials/SelectedProducts';
import FormDetails from './partials/FormDetails';
import { updateDocEditMetaData } from '../../services/';
import {
  disableForm,
  notify,
  redirectToDynamoId,
  userHasRole,
  getFormattedDate,
  userHasAccess,
} from '../../utils/helper';
import { useAppContext } from '../../Store';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import Dialog from '../styledComponents/Dialog';
import { UPDATE_FORM_STATE_AND_SOURCE, UPDATE_VIEW_DOC_USER } from '../../reducer';
import { useQueryClient } from '@tanstack/react-query';
import { useRulesQuery } from '../../services/getRules';
import { ReactComponent as JiraIcon } from '../../svg/jira.svg';
import ImplementationDetails from './partials/ImplementationDetails';
import { UserRoles, accessPolicy } from '../../constants';
import IconButton from '@mui/material/IconButton';

interface DocEditContentProps {
  control: any;
  errors: any;
  handleBulkSave?: (metadata: any, rawRules: any) => void;
  handlePublish?: () => void;
  handleDelete?: (loaderFunc: () => void) => void;
  handleSave: () => void;
  id?: string;
  isLoading: boolean;
  open: boolean;
  searchHits?: readonly any[];
  setOpen: (bool: boolean) => void;
  alertMessage?: [string, 'success' | 'error'] | null;
  title: string;
  viewDoc?: any;
  isPublishLoading?: boolean;
  watch: UseFormReturn['watch'];
  isValid?: boolean;
  setError?: UseFormReturn['setError'];
  clearErrors?: UseFormReturn['clearErrors'];
  onChange?: FormEventHandler<HTMLFormElement>;
  requiredKeys: string[];
}

function DocEditContent({
  handleSave,
  title,
  id,
  alertMessage,
  open,
  setOpen,
  control,
  errors,
  isLoading,
  handleBulkSave,
  handlePublish,
  handleDelete,
  searchHits,
  viewDoc = {},
  isPublishLoading = false,
  watch,
  isValid,
  setError,
  clearErrors,
  onChange,
  requiredKeys,
}: DocEditContentProps) {
  const queryClient = useQueryClient();
  const { state, dispatch } = useAppContext();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [overwriteDialogOpen, setOverwriteDialogOpen] = useState(false);
  const navigate = useNavigate();
  const { data: rulesData } = useRulesQuery(id);
  const [alertText, alertType] = alertMessage ?? '';
  const [isDeleting, setIsDeleting] = useState(false);
  const disabled = isDeleting || disableForm(viewDoc, state.auth);
  const isOwner = viewDoc.user?.email === state.auth.user.email;
  const [statusToggle, setStatusToggle] = useState(isOwner && viewDoc.status === 'EDIT');
  const userRoles = state.auth.roles as UserRoles[];
  const isAdmin = userHasRole([UserRoles.ADMIN], state.auth.roles as UserRoles[]);
  const isBusinessUser = userHasRole([UserRoles.BUSINESSUSER], state.auth.roles as UserRoles[]);
  const [isProductsComplete, setIsProductsComplete] = useState<boolean>(false);
  const storedLinkData = localStorage.getItem('link');
  const linkData = storedLinkData ? JSON.parse(storedLinkData) : '';
  const [isUpdatingForm, setIsUpdatingForm] = useState<boolean>(false);
  const [isValidFormNumber, setIsValidFormNumber] = useState<boolean>(true);
  const [isFormNumberFocused, setIsFormNumberFocused] = useState<boolean>(false);
  const [submitDialogOpen, setSubmitDialogOpen] = useState<boolean>(false);
  const location = useLocation();

  const form_number = watch('form_number');

  useEffect(() => {
    if ((viewDoc.status === 'EDIT') !== statusToggle) {
      setStatusToggle(isOwner && viewDoc.status === 'EDIT');
    }

    /* eslint-disable */
  }, [viewDoc.status]);
  /* eslint-enable */

  const getMatch = useCallback(
    (value: string) => searchHits!.find((hit) => hit?._source?.form_number === value),
    [searchHits]
  );
  useEffect(() => {
    const found = searchHits ? getMatch(form_number) : undefined;
    const foundFormNumber = found?._source?.form_number;
    if (!!form_number && foundFormNumber) {
      setError &&
        setError('form_number', { type: 'custom', message: 'Form Number Already Exists' });
      setIsValidFormNumber(false);
      if (!isFormNumberFocused) setDialogOpen(true);
    } else {
      clearErrors && clearErrors('form_number');
      setIsValidFormNumber(true);
    }
  }, [form_number, searchHits, isFormNumberFocused, getMatch, clearErrors, setError]);

  const redirectMatchedId = async (value: string) => {
    const found = getMatch(value);
    if (found) {
      const id = found._id;
      await redirectToDynamoId(id, state, navigate);
    }
  };

  const handleStatus = async (isDraft = false) => {
    setIsUpdatingForm(true);
    setStatusToggle(true);
    let options = {
      status: 'EDIT' as Status,
    };
    if (statusToggle || isDraft) {
      options.status = 'SAVED';
    }
    try {
      const form = await updateDocEditMetaData(id!, {}, options);
      if (form && form.data) {
        queryClient.invalidateQueries({ queryKey: ['docEditFile'] });
        dispatch({ type: UPDATE_FORM_STATE_AND_SOURCE, payload: { status: form.data.status } });
        dispatch({ type: UPDATE_VIEW_DOC_USER, payload: form.data.user.email });

        if (!isDraft) {
          setStatusToggle(form.data.status === 'EDIT');
        }
      }
      if(isDraft) {
        notify('Succesfully submitted form changes, please return to Jira.', 'success', 5000, {
          onClose: () => setIsUpdatingForm(false)});
      }
    } catch (error) {
      console.log("Failed to update form: ", error);
    } finally {
      setIsUpdatingForm(false);
    }
  };

  return (
    <Box className="wrapper container">
      <Box className="content-box">
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={() => setOpen(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setOpen(false)}
            severity={alertType === 'error' ? 'error' : 'success'}
            sx={{
              width: 0.5,
              border: '2px solid',
              borderColor: alertType === 'error' ? 'error.light' : 'success.light',
            }}
          >
            {alertText}
          </Alert>
        </Snackbar>
        <Box sx={{ flexGrow: 1, position: 'relative' }}>
          <Stack spacing={3}>
            <Stack direction="row">
              <Stack>
                <h2 className="page-title">{title}</h2>
              </Stack>
              {!id && linkData && (
                <Stack direction={'row'} sx={{ padding: '12px' }}>
                  <JiraIcon style={{ height: 25, width: 25, marginRight: '8px' }} />
                  <Typography>{linkData.id}</Typography>
                </Stack>
              )}
              {/* PM user navigates to elastic ID Edit Form page with link data */}
              {id && location.state?.link && (
                <Stack direction={'row'} sx={{ padding: '12px' }}>
                  <JiraIcon style={{ height: 25, width: 25, marginRight: '8px' }} />
                  <Typography>{location.state.link.id}</Typography>
                </Stack>
              )}
              {userHasAccess(accessPolicy.fileUpload.read, userRoles) && (
                <Stack sx={{ flex: 1 }}>
                  <FileUpload id={id} disabled={disabled || id === undefined} />
                </Stack>
              )}
            </Stack>
            <Stack sx={{ '&.MuiStack-root': { marginTop: '-10px' } }}>
              {id && userHasAccess(accessPolicy.form.write, userRoles) && (
                <Box sx={{ '&.MuiBox-root': { mr: 'auto' } }}>
                  <FormControlLabel
                    disabled={!isOwner && !isAdmin && viewDoc.status === 'EDIT'}
                    labelPlacement="end"
                    control={
                      <Switch
                        checked={statusToggle}
                        onChange={() =>
                          viewDoc.formSource === 'INDEX'
                            ? handleBulkSave && handleBulkSave(viewDoc.metadata, rulesData.data)
                            : (viewDoc.status === 'EDIT' || viewDoc.status === 'DRAFT') && !isOwner
                            ? setOverwriteDialogOpen(true)
                            : handleStatus()
                        }
                      />
                    }
                    label={
                      viewDoc.status === 'DRAFT'
                        ? 'In edit by a Business User'
                        : viewDoc.status === 'EDIT'
                        ? isOwner
                          ? 'In edit by you'
                          : 'In edit by another user'
                        : 'Edit'
                    }
                  ></FormControlLabel>
                  {[
                    viewDoc.status === 'EDIT' && (
                      <Tooltip
                        title={
                          isOwner
                            ? 'Switch edit off to allow other users to modify this form'
                            : isAdmin
                            ? 'Switch edit on to overwrite'
                            : ''
                        }
                        key="edit-toggle"
                      >
                        <InfoOutlinedIcon sx={{ verticalAlign: 'middle' }} />
                      </Tooltip>
                    ),
                    [
                      userHasAccess(accessPolicy.form.write, userRoles) && [
                        viewDoc.status === 'SAVED' && (
                          <Tooltip title="Switch edit on to update the form" key="tooltip1">
                            <InfoOutlinedIcon sx={{ verticalAlign: 'middle' }} />
                          </Tooltip>
                        ),
                        viewDoc.status === 'DRAFT' && (
                          <Tooltip title="Switch edit on to overwrite" key="tooltip2">
                            <InfoOutlinedIcon sx={{ verticalAlign: 'middle' }} />
                          </Tooltip>
                        ),
                      ],
                    ],
                    viewDoc.user?.email && (
                      <Tooltip title={viewDoc.user?.email} key="email-tooltip" placement="right">
                        <IconButton
                          href={`mailto:${viewDoc.user?.email}`}
                          aria-label="launch"
                          style={{ backgroundColor: 'transparent', color: '#041c2c' }}
                        >
                          <EmailOutlinedIcon sx={{ verticalAlign: 'middle' }} />
                        </IconButton>
                      </Tooltip>
                    ),
                    viewDoc.status === 'EDIT' && isOwner && (
                      <p key={'info-key'}>
                        <em>Switch edit off to allow other users to modify this form</em>
                      </p>
                    ),
                  ]}
                </Box>
              )}
            </Stack>
            <form onChange={onChange}>
              <Stack spacing={3} width={0.5}>
                {!id &&
                userHasAccess(accessPolicy.formNumberAutocomplete.read, userRoles) &&
                searchHits ? (
                  <Controller
                    control={control}
                    name="form_number"
                    render={({ field: { ref, onChange, value, ...field } }) => (
                      <Autocomplete
                        sx={{ width: 300 }}
                        freeSolo
                        disableClearable
                        onChange={(_, data) => {
                          onChange(data);
                        }}
                        blurOnSelect={true}
                        onBlur={() => setIsFormNumberFocused(false)}
                        onFocus={() => setIsFormNumberFocused(true)}
                        value={form_number}
                        filterOptions={(hits) => hits.map(({ _source }) => _source.form_number)}
                        options={searchHits}
                        renderInput={(params) => (
                          <MuiTextField
                            {...params}
                            {...field}
                            onChange={(event: any) => onChange(event?.target.value)}
                            disabled={id !== undefined}
                            error={!!errors?.form_number}
                            id="form_number"
                            fullWidth
                            label={`${requiredKeys.includes('form_number') ? '*' : ''} Form Number`}
                            aria-invalid={errors?.form_number ? 'true' : 'false'}
                            variant="outlined"
                          />
                        )}
                      />
                    )}
                  />
                ) : (
                  <TextField
                    id="form_number"
                    label={`${requiredKeys.includes('form_number') ? '*' : ''} Form Number`}
                    sx={{ width: 300 }}
                    control={control}
                    errors={errors}
                    disabled={disabled || !viewDoc.canModifyFormNumber}
                  />
                )}
                <TextField
                  id="form_title"
                  label={`${requiredKeys.includes('form_title') ? '*' : ''} Form Title`}
                  control={control}
                  errors={errors}
                  disabled={disabled}
                />
              </Stack>
              <ConditionalFieldsConfigurator
                control={control}
                disabled={disabled}
                errors={errors}
                implDetailsDefaultExpanded={isBusinessUser}
                onChange={onChange}
                watch={watch}
                requiredKeys={requiredKeys}
              />
            </form>
            <Box sx={{ paddingTop: 1 }}>
              {id && (
                <SelectedProducts
                  formId={id}
                  setIsProductsComplete={setIsProductsComplete}
                  isProductsComplete={isProductsComplete}
                />
              )}
            </Box>
          </Stack>
          <Divider sx={{ pt: 3 }} />
          <Stack direction="row" spacing={4} justifyContent="flex-end" sx={{ pt: 3 }}>
            {!(viewDoc.formSource === 'INDEX' || viewDoc.status === 'PUBLISHED') && !id ? (
              <Button
                variant="outlined"
                onClick={handleSave}
                disabled={(isBusinessUser && !linkData) || !isValidFormNumber}
                isLoading={isLoading}
              >
                Create Form
              </Button>
            ) : (
              [
                userHasAccess(accessPolicy.formPublish.read, userRoles) && (
                  <Button
                    disabled={disabled || !isValid|| !isProductsComplete}
                    onClick={() => handlePublish && handlePublish()}
                    isLoading={isPublishLoading}
                    key="publish"
                  >
                    Publish
                  </Button>
                ),
                userHasAccess(accessPolicy.formSubmit.read, userRoles) && (
                  <Button
                    disabled={disabled || !isValid || !isProductsComplete}
                    onClick={() => {
                      setSubmitDialogOpen(true);
                    }}
                    isLoading={isUpdatingForm}
                    key="submit"
                  >
                    Submit
                  </Button>
                ),
                userHasAccess(accessPolicy.form._delete, userRoles) && (
                  <Button
                    disabled={disabled}
                    variant="outlined"
                    color="error"
                    onClick={() => {
                      setDeleteDialogOpen(true);
                    }}
                    key="delete"
                  >
                    Delete
                  </Button>
                ),
              ]
            )}
          </Stack>
        </Box>
      </Box>
      {dialogOpen && (
        <Dialog
          open={dialogOpen}
          closeDialog={() => setDialogOpen(false)}
          id={form_number}
          cancelText="Change Form number"
          maxWidth={480}
          confirmText="Continue to Edit"
          confirmClick={() => redirectMatchedId(form_number)}
          disableRestoreFocus={true}
        >
          <Typography>
            Form number {form_number} already exists, do you want to edit or change the form number?
          </Typography>
        </Dialog>
      )}
      {deleteDialogOpen && (
        <Dialog
          open={deleteDialogOpen}
          closeDialog={() => setDeleteDialogOpen(false)}
          id={'delete-dialog'}
          cancelText="Cancel"
          maxWidth={480}
          confirmText="Yes, remove"
          confirmClick={() => {
            setDeleteDialogOpen(false);
            setIsDeleting(true);
            if (handleDelete) handleDelete(() => setIsDeleting(false));
          }}
        >
          <Typography>
            Are you sure you want to remove the form? Note that this will remove it for all listed
            products on the form.
          </Typography>
        </Dialog>
      )}
      {overwriteDialogOpen && (
        <Dialog
          open={overwriteDialogOpen}
          closeDialog={() => setOverwriteDialogOpen(false)}
          id={'overwrite-ownership'}
          cancelText="Cancel"
          maxWidth={480}
          confirmText="Continue to edit"
          confirmClick={() => {
            setOverwriteDialogOpen(false);
            handleStatus();
          }}
        >
          <Typography>
            In edit by another user. Do you want to override the change to continue to edit?
          </Typography>
        </Dialog>
      )}
      {submitDialogOpen && (
        <Dialog
          open={submitDialogOpen}
          closeDialog={() => setSubmitDialogOpen(false)}
          id={'submit-dialog'}
          cancelText="Cancel"
          maxWidth={480}
          confirmText="OK"
          confirmClick={() => {
            setSubmitDialogOpen(false);
            handleStatus(true);
          }}
        >
          <Typography>Please confirm you are finished with your edits.</Typography>
        </Dialog>
      )}
      {id && viewDoc.timestamp && (
        <Typography variant="timestamp">
          Last saved: {getFormattedDate(viewDoc.timestamp)}
        </Typography>
      )}
    </Box>
  );
}

interface ConditionalFieldsConfiguratorProps {
  control: any;
  errors: any;
  disabled: boolean;
  onChange: any;
  watch: UseFormReturn['watch'];
  implDetailsDefaultExpanded: boolean;
  requiredKeys: string[];
}

export enum DependentFields {
  'premium_info',
  'named_insured',
  'policy_number',
  'state',
  'issuance_options',
  'variable_data_instructions',
  'form_usage_rule',
}

const ConditionalFieldsConfigurator = ({
  control,
  errors,
  disabled,
  watch,
  implDetailsDefaultExpanded,
  onChange,
  requiredKeys,
}: ConditionalFieldsConfiguratorProps) => {
  //This watch I believe is needed.  These fields need to be watched as their values determine what dependentFields are displayed
  const [premium_bearing, form_category, variable_static, form_usage_category, form_type] = watch([
    'premium_bearing',
    'form_category',
    'variable_static',
    'form_usage_category',
    'form_type',
  ]);

  //key: fieldName, value: Array of fieldValues
  const conditionalFieldsMap = {
    premium_bearing: ['Yes'],
    form_category: ['M-Form', 'T-Form'],
    form_type: [
      'Certificate',
      'Endorsement',
      'M-Form',
      'Policyholder Notice',
      'Selection/Rejection',
      'State Amendatory',
      'T-Form',
      'Other',
    ],
    variable_static: ['Variable'],
    form_usage_category: ['Conditional', 'Optional'],
  };

  const isFieldDisplayed = (id: DependentFields) => {
    if (id === DependentFields.premium_info) {
      return conditionalFieldsMap.premium_bearing.includes(premium_bearing);
    }
    if (
      id === DependentFields.named_insured ||
      id === DependentFields.policy_number ||
      id === DependentFields.state
    ) {
      return conditionalFieldsMap.form_category.includes(form_category);
    }
    if (id === DependentFields.issuance_options) {
      return conditionalFieldsMap.form_type.includes(form_type);
    }
    if (id === DependentFields.variable_data_instructions) {
      return conditionalFieldsMap.variable_static.includes(variable_static);
    }
    if (id === DependentFields.form_usage_rule) {
      return conditionalFieldsMap.form_usage_category.includes(form_usage_category);
    } else return false;
  };

  return (
    <>
      <FormDetails
        requiredKeys={requiredKeys}
        control={control}
        onChange={onChange}
        errors={errors}
        disabled={disabled}
      />
      <ImplementationDetails
        control={control}
        errors={errors}
        disabled={disabled}
        isFieldDisplayed={isFieldDisplayed}
        defaultExpanded={implDetailsDefaultExpanded}
        onChange={onChange}
        requiredKeys={requiredKeys}
      />
    </>
  );
};

export default DocEditContent;
