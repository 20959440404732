import React, { useEffect, useState } from 'react';
import { Stack } from '@mui/system';
import _ from 'lodash';
import { fetchRanges, saveRanges } from '../../../api';
import { OldSelect } from '../../styledComponents/Select/Select';
import Button from '../../styledComponents/Button';
import Dialog from '../../styledComponents/Dialog';
import { stateCodeArray } from '../../../constants/defaultValues';
import { useAppContext } from '../../../Store';
import moment, { Moment } from 'moment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { disableForm } from '../../../utils/helper';
import { useDocEditFileQuery } from '../../../services/getDocEditFile';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import { useGetOrg } from '../../../services/getOrg';
import { NormalizedStatus, stateStatusObject } from '../constants';
import { FormHelperText } from '@mui/material';
import ColorLensOutlinedIcon from '@mui/icons-material/ColorLensOutlined';

//TODO: ENUM carrier and status options when we know them 100%
export type StateData = {
  stateCode: string;
  status: StatusKey;
  allTabStatus?: NormalizedStatus[];
  carrier: string;
  effectiveDate: Moment | null;
  expirationDate: Moment | null;
};

export type StateSelectOptions = {
  label: string;
  selected?: -1 | 0 | 1; //-1: Already saved state 0: unselected 1: currently selected to be saved
};

type ApplicableStatesProps = {
  formId: string;
  indexId?: string;
  index: number;
  complete: boolean;
  fetchRangesError: any;
};

const emptyObj: StateData = {
  stateCode: '',
  status: '',
  carrier: '',
  effectiveDate: null,
  expirationDate: null,
};

type StatusKey = keyof typeof stateStatusObject;

const sortSelecetedStatesByCarrier = (selectedStates: StateData[]) => {
  if (selectedStates.length === 0) return [];

  const sortedStates = _.clone(selectedStates);

  //sorts by carrier and then alphabetically for each carrier
  sortedStates.sort((a, b) =>
    a.carrier > b.carrier
      ? 1
      : b.carrier > a.carrier
      ? -1
      : 0 || a.stateCode > b.stateCode
      ? 1
      : b.stateCode > a.stateCode
      ? -1
      : 0
  );

  let currentCarrierArray: any = [];
  let statesGroupedByCarrier: any = [];

  sortedStates.forEach((obj, index) => {
    if (index === 0) currentCarrierArray.push(obj);
    else if (obj.carrier !== currentCarrierArray[0].carrier) {
      statesGroupedByCarrier.push(currentCarrierArray);
      currentCarrierArray = [obj];
    } else currentCarrierArray.push(obj);
  });

  statesGroupedByCarrier.push(currentCarrierArray);

  let finalArrayByCarrier = [];

  if (statesGroupedByCarrier.length === 1) {
    finalArrayByCarrier = statesGroupedByCarrier;
  } else {
    //remove duplicates, alphbatically sort the ALL array, store multiple statuses in comma delimited string
    const allMap: any = {};
    sortedStates.forEach((state) => {
      if (allMap[state.stateCode]) {
        allMap[state.stateCode].allTabStatus.push(stateStatusObject[state.status]);
      } else {
        state.allTabStatus = [stateStatusObject[state.status]];
        allMap[state.stateCode] = state;
      }
    });

    const allArray: StateData[] = Object.values(allMap);

    finalArrayByCarrier = [
      allArray.sort((a, b) => (a.stateCode > b.stateCode ? 1 : b.stateCode > a.stateCode ? -1 : 0)),
    ].concat(statesGroupedByCarrier);
  }

  return finalArrayByCarrier;
};

const ApplicableStates: React.FC<ApplicableStatesProps> = ({
  formId,
  indexId,
  index,
  complete,
  fetchRangesError,
}) => {
  const { data: docEditFileData } = useDocEditFileQuery(formId);
  const { data: orgData } = useGetOrg();
  // eslint-disable-next-line  @typescript-eslint/no-unused-vars
  const { state, dispatch } = useAppContext();
  const [stateObj, setStateObj] = useState<StateData>(emptyObj);
  const [selectedStates, setSelectedStates] = useState<StateData[]>([]);
  const [byCarrier, setByCarrier] = useState<StateData[][]>([]);
  const [stateSelectOptions, setStateSelectOptions] = useState<StateSelectOptions[]>([]);
  const [isNewState, setIsNewState] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSelectAll, setisSelectAll] = useState<boolean>(true);
  const [originalStateCarrier, setOriginalStateCarrier] = useState<string>('');
  const [showError, setShowError] = useState<boolean>(false);
  const [tabValue, setTabValue] = useState<string>('0');

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const disabled = disableForm(docEditFileData?.data, state.auth);

  useEffect(() => {
    const initialStateInfoFetch = async () => {
      setIsLoading(true);
      await getStateInfo();
      setIsLoading(false);
    };
    initialStateInfoFetch();
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const finalArrayByCarrier = sortSelecetedStatesByCarrier(selectedStates);
    if (tabValue >= finalArrayByCarrier.length) setTabValue('0');
    setByCarrier(finalArrayByCarrier);
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [selectedStates]);

  const getStateInfo = async () => {
    if (!complete) {
      return;
    }

    if (formId) {
      const applicableStates = await fetchRanges(
        docEditFileData.data.formSource === 'INDEX' && indexId ? indexId : formId,
        index
      );
      if (applicableStates instanceof Error) {
        fetchRangesError(true);
      }

      if (applicableStates?.data?.ranges)
        setSelectedStates(Object.values(applicableStates.data.ranges));
    }
  };

  const removeSelectedState = async () => {
    const newSelectedStates = _.clone(selectedStates);
    newSelectedStates.splice(
      newSelectedStates.findIndex(
        (x: any) => x.stateCode === stateObj.stateCode && x.carrier === stateObj.carrier
      ),
      1
    );
    setSelectedStates(newSelectedStates);
    await saveRanges(formId, index, newSelectedStates).catch((err) => console.log(err));
  };

  const openDialog = (stateObj?: StateData) => {
    setShowError(false);
    if (stateObj) {
      setStateObj({
        ...stateObj,
        effectiveDate: stateObj.effectiveDate ? moment(stateObj.effectiveDate) : null,
      });
      setIsNewState(false);
      setOriginalStateCarrier(stateObj.carrier);

      setStateSelectOptions([{ label: stateObj.stateCode }]);
    } else {
      const carrier =
        tabValue === '0' && byCarrier.length !== 1
          ? orgData?.carrierOptions[0].value ?? ''
          : byCarrier[parseInt(tabValue)][0].carrier;

      setStateObj({ ...emptyObj, carrier: carrier });
      setIsNewState(true);
      setStateSelectOptions(
        stateCodeArray.map((x) => {
          return { label: x.key, selected: 0 };
        })
      );
    }
    setisSelectAll(true);
    setDialogOpen(true);
  };

  const closeDialog = (isDelete = false) => {
    if (!isNewState && isDelete) removeSelectedState();
    setDialogOpen(false);
  };

  const handleSave = async () => {
    //form validation
    if (
      (!stateObj.stateCode && !stateSelectOptions.some((x) => x.selected === 1)) ||
      (!stateObj.effectiveDate?.isValid() &&
        (stateObj.status === 'Approved' || stateObj.status === 'Approved - Desk Filing')) ||
      !stateObj.carrier
    ) {
      setShowError(true);
      return;
    }

    let updatedSelectedStates = _.clone(selectedStates);

    if (isNewState) {
      setSelectedStates((prevState) => {
        const statesToAdd = stateSelectOptions.filter((x) => x.selected === 1).map((x) => x.label);
        const newStates: StateData[] = statesToAdd.map((code: string) => {
          return {
            stateCode: code,
            status: stateObj.status,
            carrier: stateObj.carrier,
            effectiveDate: stateObj.effectiveDate,
            expirationDate: stateObj.expirationDate,
          };
        });

        // override existing states with new state, remove overrides from newState array
        updatedSelectedStates = prevState.map((state) => {
          const overrideStateIndex = newStates.findIndex(
            (s) => s.stateCode === state.stateCode && s.carrier === state.carrier
          );
          if (overrideStateIndex !== -1) {
            const overrideState = newStates[overrideStateIndex];
            newStates.splice(overrideStateIndex, 1);
            return overrideState;
          } else return state;
        });

        // combine prevState with overrides WITH remaining new states
        updatedSelectedStates = newStates.concat(updatedSelectedStates);

        return updatedSelectedStates;
      });
      setTabValue('0');
    } else {
      updatedSelectedStates[
        updatedSelectedStates.findIndex(
          (x) => x.stateCode === stateObj.stateCode && x.carrier === originalStateCarrier
        )
      ] = stateObj as StateData;
      setSelectedStates(updatedSelectedStates);
    }

    await saveRanges(formId, index, updatedSelectedStates).catch((err) => console.log(err));

    setDialogOpen(false);
  };

  const handleStateColor = (obj: StateData) => {
    if (stateStatusObject[obj.status] === NormalizedStatus.PENDING) return 'success';
    if (stateStatusObject[obj.status] === NormalizedStatus.APPROVED) return 'primary';
    return 'warning';
  };

  const handleAllTabColor = (statusArray: NormalizedStatus[]) => {
    const green = '#84c887';
    const yellow = 'rgba(255, 239, 201, 1)';
    const blue = 'rgba(38, 97, 227, .3)';

    const isApproved = statusArray.includes(NormalizedStatus.APPROVED);
    const isPending = statusArray.includes(NormalizedStatus.PENDING);
    const isOther = statusArray.includes(NormalizedStatus.OTHER);

    if (isApproved && isPending && isOther) {
      return `linear-gradient(
        to right,
        ${blue} 0%, 
        ${blue} 33.33%, 
        ${green} 33.33%, 
        ${green} 66.66%, 
        ${yellow} 66.66%, 
        ${yellow} 100% 
      )`;
    }

    if (isApproved && isOther) return `linear-gradient(to right, ${blue} 50%, ${yellow} 50%)`;
    if (isApproved && isPending) return `linear-gradient(to right, ${blue} 50%, ${green} 50%)`;
    if (isOther && isPending) return `linear-gradient(to right, ${yellow} 50%, ${green} 50%)`;

    if (isApproved) return blue;
    if (isOther) return yellow;
    if (isPending) return green;
  };

  return (
    <>
      <Stack>
        <Stack sx={{ display: 'flex', flexWrap: 'wrap-reverse', justifyContent: 'start' }}>
          <Box sx={{ width: '100%' }}>
            {byCarrier.length !== 0 && (
              <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', overflow: 'auto' }}>
                  <TabList onChange={handleTabChange} variant="scrollable">
                    {byCarrier.map((carrierArray: any, index) => (
                      <Tab
                        key={index}
                        label={
                          index === 0 && byCarrier.length > 1
                            ? 'All Applicable States'
                            : carrierArray[0].carrier
                            ? carrierArray[0].carrier
                            : 'No Carrier'
                        }
                        value={index.toString()}
                        sx={{ textTransform: 'capitalize' }}
                      />
                    ))}
                  </TabList>
                </Box>

                {byCarrier.map((carrierArray: StateData[], index) => {
                  return (
                    <Stack
                      direction="row"
                      key={index === 0 && byCarrier.length > 1 ? 'All' : carrierArray[0].carrier}
                      sx={{ pb: 0 }}
                    >
                      <TabPanel value={index.toString()}>
                        {carrierArray.map((obj: StateData) => {
                          return (
                            <Button
                              key={obj.stateCode + obj.carrier}
                              sx={{
                                width: 40,
                                height: 40,
                                borderRadius: 0,
                                '& .MuiButton-startIcon': { margin: 0 },
                                minWidth: 40,
                                marginLeft: 0.5,
                                marginRight: 0.5,
                                marginBottom: 1,
                                ...(index === 0 &&
                                  !disabled &&
                                  byCarrier.length > 1 && {
                                    '&.Mui-disabled': {
                                      background: handleAllTabColor(obj.allTabStatus || []),
                                      color: 'rgba(0,0,0,0.6)',
                                    },
                                  }),
                              }}
                              variant={
                                index === 0 && byCarrier.length > 1 ? 'outlined' : 'contained'
                              }
                              color={handleStateColor(obj)}
                              onClick={() => openDialog(obj)}
                              disabled={
                                disabled || !complete || (index === 0 && byCarrier.length > 1)
                              }
                            >
                              {obj.stateCode}
                            </Button>
                          );
                        })}
                        {index === 0 && byCarrier.length > 1 && (
                          <Tooltip
                            title={
                              <p style={{ fontSize: '14px' }}>
                                States are Read Only when viewing All States. Duplicates are only
                                shown once.
                              </p>
                            }
                          >
                            <InfoOutlinedIcon
                              sx={{ position: 'relative', right: '-11px', top: '4px' }}
                            ></InfoOutlinedIcon>
                          </Tooltip>
                        )}
                      </TabPanel>
                    </Stack>
                  );
                })}
              </TabContext>
            )}
          </Box>

          <Stack direction="row" sx={{ alignItems: 'center' }}>
            {selectedStates.length !== 0 && (
              <Tooltip
                placement="right"
                title={
                  <Stack direction="row" spacing={3}>
                    <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                      <div
                        style={{ backgroundColor: '#2661e3', width: '20px', height: '20px' }}
                      ></div>
                      <span>Approved</span>
                    </Stack>
                    <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                      <div
                        style={{ backgroundColor: '#2e7d32', width: '20px', height: '20px' }}
                      ></div>
                      <span>Pending</span>
                    </Stack>
                    <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                      <div
                        style={{ backgroundColor: '#FDB913', width: '20px', height: '20px' }}
                      ></div>
                      <span>Other</span>
                    </Stack>
                  </Stack>
                }
              >
                <ColorLensOutlinedIcon />
              </Tooltip>
            )}
            <Button
              sx={{
                height: 40,
                maxWidth: 98,
                marginLeft: 'auto',
                p: '5px 15px',
              }}
              color={!isLoading && selectedStates.length === 0 ? 'error' : 'primary'}
              variant={'outlined'}
              onClick={() => openDialog()}
              disabled={disabled || !complete}
            >
              Add States
            </Button>
          </Stack>
        </Stack>
      </Stack>

      {/* TODO: move this dialog into its own comp or function to reduce size of jsx block */}
      <Dialog
        open={dialogOpen}
        title={
          stateObj.stateCode
            ? `State Filing Information - ${stateObj.stateCode}`
            : 'State Filing Information'
        }
        id="statesDialog"
        closeDialog={() => closeDialog()}
        minWidth={0.35}
        maxWidth={0.75}
        cancelText={isNewState ? 'Cancel' : 'Delete'}
        cancelClick={() => closeDialog(true)}
        confirmText="Save"
        confirmClick={handleSave}
      >
        <Stack spacing={2} sx={{ maxWidth: '600px' }}>
          <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
            <OldSelect
              id={'Carrier'}
              label={'Carrier'}
              value={stateObj.carrier}
              handleChange={(e) => {
                let newObj = { ...stateObj, carrier: e.target.value };
                setStateObj(newObj);
              }}
              //when editing a state assignment, filter out carriers in which that state already exists
              options={
                !stateObj.stateCode
                  ? orgData?.carrierOptions ?? []
                  : orgData?.carrierOptions.filter((carrier) => {
                      if (carrier.value === stateObj.carrier) return true;
                      return !selectedStates.some(
                        (otherStateObj) =>
                          otherStateObj.carrier === carrier.value &&
                          otherStateObj.stateCode === stateObj.stateCode
                      );
                    }) ?? []
              }
              labelSize="small"
              selectSize="small"
              required={true}
              error={showError && !stateObj.carrier}
            />
            {!stateObj.stateCode && (
              <Tooltip
                title={
                  <p style={{ fontSize: '14px' }}>
                    Selectable states depend on carrier selection. Choose a carrier before selecting
                    states.
                  </p>
                }
              >
                <InfoOutlinedIcon></InfoOutlinedIcon>
              </Tooltip>
            )}
          </Stack>
          {!stateObj.stateCode && (
            <>
              <Stack direction="row" sx={{ flexWrap: 'wrap' }}>
                {stateSelectOptions.map((obj, index: number) => (
                  <Button
                    key={obj.label}
                    variant={obj.selected ? 'contained' : 'outlined'}
                    sx={{
                      width: 40,
                      height: 40,
                      borderRadius: 0,
                      '& .MuiButton-startIcon': { margin: 0 },
                      minWidth: 40,
                      marginLeft: 0.5,
                      marginRight: 0.5,
                      marginBottom: 1,
                    }}
                    onClick={() => {
                      let items = [...stateSelectOptions];
                      let item: StateSelectOptions = {
                        ...items[index],
                        selected: items[index].selected === 1 ? 0 : 1,
                      };
                      items[index] = item;
                      setStateSelectOptions(items);
                    }}
                    disabled={obj.selected === -1}
                    color={handleStateColor(stateObj)}
                  >
                    {obj.label}
                  </Button>
                ))}
                <Button
                  key={'select-all'}
                  color="success"
                  sx={{
                    width: 88,
                    height: 40,
                    borderRadius: 0,
                    '& .MuiButton-startIcon': { margin: 0 },
                    minWidth: 40,
                    marginLeft: 0.5,
                  }}
                  onClick={() => {
                    let items = [...stateSelectOptions];
                    setStateSelectOptions(
                      items.map((state) => {
                        const value = isSelectAll ? 1 : 0;
                        return {
                          label: state.label,
                          selected: value,
                        };
                      })
                    );
                    setisSelectAll(!isSelectAll);
                  }}
                >
                  {isSelectAll ? 'Select All' : 'Deselect All'}
                </Button>
                <FormHelperText error sx={{ marginTop: '10px', marginLeft: '16px' }}>
                  {showError &&
                    !stateSelectOptions.some((x) => x.selected === 1) &&
                    'A state selection is required'}
                </FormHelperText>
              </Stack>
            </>
          )}

          <Stack sx={{ minWidth: '44%' }} spacing={2}>
            <OldSelect
              id={'Status'}
              label={'Status'}
              value={stateObj.status}
              handleChange={(e) => {
                let newObj = { ...stateObj, status: e.target.value };
                setStateObj(newObj);
              }}
              options={Object.keys(stateStatusObject).map((status) => ({
                label: status,
                value: status,
              }))}
              labelSize="small"
              selectSize="small"
            />
            <Stack direction="row" spacing={2}>
              <DatePicker
                sx={{ width: '50%' }}
                label="Effective Date"
                value={stateObj.effectiveDate ? moment(stateObj.effectiveDate) : null}
                maxDate={
                  stateObj.expirationDate && moment(stateObj.expirationDate).subtract(1, 'd')
                }
                onChange={(newValue) => {
                  let newObj = { ...stateObj, effectiveDate: newValue };
                  setStateObj(newObj);
                }}
                slotProps={{
                  textField: {
                    required:
                      stateObj.status === 'Approved' || stateObj.status === 'Approved - Desk Filing'
                        ? true
                        : false,
                    error:
                      showError &&
                      !stateObj.effectiveDate &&
                      (stateObj.status === 'Approved' ||
                        stateObj.status === 'Approved - Desk Filing'),
                  },
                }}
              />
              <DatePicker
                sx={{ width: '50%' }}
                label="Expiration Date"
                value={stateObj.expirationDate ? moment(stateObj.expirationDate) : null}
                minDate={stateObj.effectiveDate && moment(stateObj.effectiveDate).add(1, 'd')}
                onChange={(newValue) => {
                  let newObj = { ...stateObj, expirationDate: newValue };
                  setStateObj(newObj);
                }}
              />
            </Stack>
          </Stack>
        </Stack>
      </Dialog>
    </>
  );
};

export default ApplicableStates;
