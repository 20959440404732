import React, { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Navigate } from 'react-router-dom';
import { toRelativeUrl } from '@okta/okta-auth-js';

function AuthMain() {
  const { authState, oktaAuth } = useOktaAuth();
  const [redirectUri] = useState('/search');

  useEffect(() => {
    // Immediate redirect to Okta
    if (authState && !authState.isPending && !authState.isAuthenticated) {
      const originalUri = toRelativeUrl(window.location.href, window.location.origin);
      oktaAuth.setOriginalUri(originalUri);
      oktaAuth.signInWithRedirect();
    }
  }, [authState, oktaAuth]);

  // If the user is authenticated, redirect to the search page.
  if (authState && authState.isAuthenticated) {
    return <Navigate to={redirectUri} replace />;
  }

  // If the user is NOT authenticated, and the redirect has already fired.
  return null;
}

export default AuthMain;
