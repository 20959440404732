import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';

import AuthBlock from './auth/AuthBlock';
import { useAppContext } from './Store';
import { userHasRole } from './utils/helper';
import { UserRoles } from './constants';

function PaperHeader() {
  const navigate = useNavigate();
  const { state } = useAppContext();
  const { authState } = useOktaAuth();

  if (!authState || !authState.isAuthenticated) {
    return (
      <div className="wrapper container external">
        <div className="content-box content-box-600">
          <h3>Checking authentication</h3>
          <p>Please wait...</p>
        </div>
      </div>
    )
  }

  return (
    <header>
      <Box className="wrap-full flex-row-space" displayPrint="none">
        <div className="branding-container flex-row">
          <Link to="/" className="flex-col flex-justify--around home-logo-container">
            <img
              src="/toolkit/images/Argo_Solid_White_H.svg"
              alt="Argo Group"
              className="header--logo"
            />
          </Link>
          <div className="product-name">PAPER</div>
        </div>
        <nav>
          {authState.isAuthenticated && (
            <>
              <Stack
                component="a"
                sx={{
                  fontSize: 18,
                  cursor: 'pointer',
                }}
                onClick={() => navigate('/search')}
              >
                Search
              </Stack>
              {userHasRole(
                [UserRoles.MANAGER, UserRoles.ADMIN],
                state.auth.roles as UserRoles[]
              ) && (
                <>
                  <Stack
                    component="a"
                    sx={{
                      fontSize: 18,
                      cursor: 'pointer',
                    }}
                    onClick={() => navigate('/forms')}
                  >
                    Add/Edit Forms
                  </Stack>
                </>
              )}
              <Stack
                component="a"
                sx={{
                  fontSize: 18,
                  cursor: 'pointer',
                }}
                onClick={() => navigate('/export-metadata')}
              >
                Form Sets Export
              </Stack>
              {userHasRole([UserRoles.ADMIN], state.auth.roles as UserRoles[]) && (
                <div className="user-grp">
                  <div className="user-wrapper">
                    <div className="user">
                      <span className="user-name" style={{ fontSize: '18px' }}>
                        Admin
                      </span>
                    </div>
                  </div>

                  <div className="dropdown-nav" style={{ marginRight: '-100%', width: '195%' }}>
                    <Link to="/admin/add-org-hierarchy" className="org-hierarchy">
                      Add Org Hierarchy
                    </Link>
                    <Link to="/admin/archive-org-hierarchy" className="org-hierarchy">
                      Archive Org Hierarchy
                    </Link>
                    <Link to="/admin/dictionary" className="dictionary">
                      Dictionary
                    </Link>
                  </div>
                </div>
              )}
            </>
          )}
          <AuthBlock />
        </nav>
      </Box>
    </header>
  );
}

export default PaperHeader;
